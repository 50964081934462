<template>
  <v-dialog justify="center" :value="value" @click:outside="!pastTPRs.length || admin ? '' : $emit('update:value', false)" :persistent="!pastTPRs.length || admin" :max-width="activeTPRs.length ? '500' : '400'">
    <v-card color=#fafafa>
      <v-card-title class="text-capitalize">
        <v-icon left large :color="!pastTPRs.length || admin ? 'primary' : 'red'">
          {{!pastTPRs.length || admin ? 'mdi-help-rhombus' : 'mdi-alert'}}
        </v-icon>
          <span v-if="!pastTPRs.length || admin">{{admin || upcomingTPRs.length ? 'Remove' : 'Modify'}} {{selected.length}} TPRs?</span>
          <span v-else>Permission Denied</span>
        <v-spacer />
      </v-card-title>
      <v-card-text class="px-12">
        <span v-if="upcomingTPRs.length || (pastTPRs.length && admin)">Are you sure you want to remove TPRs?</span>
        <span v-if="activeTPRs.length">Are you sure you want to modify end date to <b>{{updateActiveDate}}</b>?</span>
        <span v-if="pastTPRs.length && !admin">This account does not have correct permissions to remove past TPRs. Please contact your administrator.</span>
      </v-card-text>
      <v-card-actions v-if="!pastTPRs.length || admin">
        <v-spacer />
        <v-btn text @click="$emit('update:value', false)">Cancel</v-btn>
        <v-btn class="grey lighten-2 ml-2" @click="confirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { notification } from '@/mixins/notification'
import { mapState } from 'vuex'

export default {
  name: 'TprConfirmDelete',

  mixins: [notification],

  props: [
    'value',
    'selected',
    'removeTPRs'
  ],

  computed: {
    ...mapState(['admin']),

    upcomingTPRs () {
      return this.selected.filter(tpr => this.moment().format('YYYY-MM-DD') < tpr.dealBeginDate)
    },

    activeTPRs () {
      return this.selected.filter(tpr => this.moment().format('YYYY-MM-DD') >= tpr.dealBeginDate && this.moment().format('YYYY-MM-DD') <= tpr.dealEndDate)
    },

    pastTPRs () {
      return this.selected.filter(tpr => this.moment().format('YYYY-MM-DD') >= tpr.dealEndDate)
    },

    updateActiveDate () {
      const upcomingSaturday = 6
      const today = this.moment().isoWeekday(7)

      if (today <= upcomingSaturday) { 
        return this.moment().isoWeekday(upcomingSaturday).format(this.$config.date_display)
      } else {
        return this.moment().add(1, 'weeks').isoWeekday(upcomingSaturday).format(this.$config.date_display)
      }
    }
  },

  methods: {
    confirm () {
      let notificationText
      if (this.activeTPRs.length) {
        notificationText = 'TPRs Successfully Updated'
      } else {
        notificationText = 'TPRs Successfully Deleted'
      }
      this.$emit('delete', notificationText)
      this.$emit('update:value', false)
    }
  }
}
</script>
