var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: !_vm.pastTPRs.length || _vm.admin,
        "max-width": _vm.activeTPRs.length ? "500" : "400"
      },
      on: {
        "click:outside": function($event) {
          !_vm.pastTPRs.length || _vm.admin
            ? ""
            : _vm.$emit("update:value", false)
        }
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-card-title",
            { staticClass: "text-capitalize" },
            [
              _c(
                "v-icon",
                {
                  attrs: {
                    left: "",
                    large: "",
                    color: !_vm.pastTPRs.length || _vm.admin ? "primary" : "red"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        !_vm.pastTPRs.length || _vm.admin
                          ? "mdi-help-rhombus"
                          : "mdi-alert"
                      ) +
                      " "
                  )
                ]
              ),
              !_vm.pastTPRs.length || _vm.admin
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.admin || _vm.upcomingTPRs.length
                          ? "Remove"
                          : "Modify"
                      ) +
                        " " +
                        _vm._s(_vm.selected.length) +
                        " TPRs?"
                    )
                  ])
                : _c("span", [_vm._v("Permission Denied")]),
              _c("v-spacer")
            ],
            1
          ),
          _c("v-card-text", { staticClass: "px-12" }, [
            _vm.upcomingTPRs.length || (_vm.pastTPRs.length && _vm.admin)
              ? _c("span", [_vm._v("Are you sure you want to remove TPRs?")])
              : _vm._e(),
            _vm.activeTPRs.length
              ? _c("span", [
                  _vm._v("Are you sure you want to modify end date to "),
                  _c("b", [_vm._v(_vm._s(_vm.updateActiveDate))]),
                  _vm._v("?")
                ])
              : _vm._e(),
            _vm.pastTPRs.length && !_vm.admin
              ? _c("span", [
                  _vm._v(
                    "This account does not have correct permissions to remove past TPRs. Please contact your administrator."
                  )
                ])
              : _vm._e()
          ]),
          !_vm.pastTPRs.length || _vm.admin
            ? _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("update:value", false)
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "grey lighten-2 ml-2",
                      on: { click: _vm.confirm }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }